import { CalendarItem } from "../components/Calendar-event";
import DefaultLayout from "../layout/DefaultLayout";

const events = [
  {
    name: "XIX Trilhão Cerro Negro",
    city: "Santo Antônio - PR",
    date: "08/12/2024",
    hour: "08:00",
    confirmed: true
  }, {
    name: "1ª Meia Trilha Tupatina Off Road com Saci",
    city: "Francisco Beltrão - PR",
    date: "30/11/2024",
    hour: "12:30",
    confirmed: true
  }, {
    name: "4ª Trilha Nutellama",
    city: "Anchieta - SC",
    date: "15/11/2024",
    hour: "07:30",
    confirmed: true
  }, {
    name: "14ª Trilha da Mata",
    city: "Planalto - PR",
    date: "03/11/2024",
    hour: "08:00",
    confirmed: true
  }, {
    name: "Trilha beneficiente - Trilheiros Capanema",
    city: "Capanema - PR",
    date: "22/09/2024",
    hour: "08:00",
    confirmed: true
  }
]

const Calendary = () => {
  return (
    <DefaultLayout>
      <section className="container">
        <div className="calendar">
          <h1>Calendário</h1>
          <h2>#PresenteNesteEvento</h2>
        </div>
        <CalendarItem events={events} />

      </section>
    </DefaultLayout>
  )
}

export default Calendary;