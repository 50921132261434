import DefaultLayout from "../layout/DefaultLayout";

const Home = () => {

	return (
		<DefaultLayout >
			<div id="post-natal" className="container">
				<div className="info" >
					<h1>JUNTOS POR UM NATAL MAIS DOCE!</h1>
					<h3>O grupo pinakay se uniu para fazer a magia do natal chegar a mais crianças!<br /> Vamos distribuir balas e doces para alegrar o coração dos pequenos e trazer um sorriso no rosto de cada um.</h3>
					<ul>
						<li>🕗 21/12/2024 as 15:00</li>
						<li>📍 Nova Esperança do Sudoeste</li>
					</ul>
				</div>

				<div className="image">
					<img src="./assets/home/natal-pinakay.jpeg" alt="Imagem natal" />
				</div>
			</div>
		</DefaultLayout>
	)
}

export default Home;