
const ParticipantProfileLine = ({ participants }) => {
  return (
    <div className="container-participants">
      {participants.map((value) => (
        <div className="container-participant" key={value.name}>
          <img src={`/assets/participants/${value.picture}`} alt={`Imagem do membro ${value.name}`} />
          <div>
            <p><strong>{value.name}</strong></p>
            <span>
              <a href={`https://instagram.com/${value.instagram}`} >
                < img src="/assets/icons/instagram.svg" alt="logo Instagram" />{value.instagram}
              </a>
            </span>

          </div>
        </div>
      ))
      }
    </div >
  )
}

export default ParticipantProfileLine;