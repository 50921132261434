import DefaultLayout from "../layout/DefaultLayout";

const Events = () => {
  return (
    <DefaultLayout>
      <section className="container">
        <h1>
          Eventos
        </h1>

      </section>
    </DefaultLayout>
  )
}

export default Events;