const DefaultLayout = ({ children }) => {

	return (
		<>
			<section className="container no-container">
				<header id="#" className="header ">
					<div >
						<a href="/">
							<img src="/assets/logo.png" alt="logo" />
						</a>
						<h1>
							Pinakay's
						</h1>

					</div>
					<nav>
						{/* <a href="/eventos">
							Eventos
						</a> */}
						<a href="/calendario">
							Calendario
						</a>
						<a href="/membros">
							Participantes
						</a>
						<a href="/sobre">
							Sobre
						</a>
					</nav>
				</header>
			</section>
			{children}
			<footer>
				<div className="logo">
					<img src="assets/logo.png" alt="logo"/>
						Pinakay's
				</div>
				<nav>
					<a href="/calendario">Calendário</a>
					<a href="/membros">Membros</a>
					<a href="/sobre">Sobre</a>
				</nav>

				<div className="social-links">
					<a href="$" title="Instagram">
						<img src="assets/icons/instagram.svg" alt="instagram"/>
					</a>
					<a href="$" title="Facebook">
						<img src="assets/icons/facebook.svg" alt="facebook"/>
					</a>
					<a href="$" title="youTube">
						<img src="assets/icons/youtube.svg" alt="youtube"/>
					</a>
					<a href="$" title="tiktok">
						<img src="assets/icons/tiktok.svg" alt="tiktok"/>
					</a>
				</div>
			</footer>
		</>

	);
};

export default DefaultLayout;