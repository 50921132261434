export const CalendarItem = ({ events }) => {
  return (
    <>
      {events.map((event) => (
        <div className="calendar-item">
          <div>
            <div>
              <h1>{event.name}</h1>
              <p>{event.city}</p>
            </div>
            <div>
              <p>Largada: {event.date} - <strong>{event.hour}</strong></p>

            </div>
          </div>
          {event.confirmed === true ? (
            <div className="confirm">
              <img src="/assets/logo.png" alt="Logo Pinakay" />

              <p>Presença confirmada</p>
            </div>
          ) : <></>}
        </div>

      ))}
    </>
  )
}