import { Navigate, Route, Routes } from 'react-router-dom';
import Participant from '../pages/Participant';
import About from '../pages/About';
import Home from '../pages/Home';
import Calendary from '../pages/Calendary';
import Events from '../pages/Events';

export const AppRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/membros" element={<Participant />} />
      <Route path="/calendario" element={<Calendary />} />
      <Route path="/eventos" element={<Events />} />
      <Route path="/sobre" element={<About />} />
      <Route path="*" element={<Navigate to={'/'}></Navigate>} />
    </Routes>
  );
};