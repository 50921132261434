import DefaultLayout from "../layout/DefaultLayout";

const About = () => {
  return (
    <DefaultLayout>
      <div className="opacity full">
        <img src="/assets/about/grupo-em-catandulama.png" alt="Imagem do Grupo no catandulama" className="image-about " />
        <section id="about" className="container">
          <div className="header">
            <h1>Os Pinakay</h1>
          </div>
          <div className="about-us">
            <img src="/assets/logo.png" alt="imagem logo" />
            <div className="info-about">
              <h2>Sobre o Grupo</h2>
              <p>Fundado em 1992, o Pinakay's é um grupo de entusiastas do off-road dedicados a explorar trilhas e compartilhar experiências. Nossa missão é promover a camaradagem entre os amantes das motos e a busca por novas aventuras.</p>
            </div>
          </div>
        </section>
      </div>
    </DefaultLayout>
  )
}

export default About;