import ParticipantProfileLine from "../components/Participant-profile";
import DefaultLayout from "../layout/DefaultLayout";

const participants = [
  {
    picture: "jose-henrique.png", name: "José Henrique B. Stang", instagram: "josestang"
  }, {
    picture: "felipe-hilmann.jpg", name: "Luiz Felipe Hilmann", instagram: "felipehilmann"
  }, {
    picture: "ricardo-stang.jpeg", name: "Ricardo Stang", instagram: "stang.ricardo"
  }, {
    picture: "henrique-borghezan.jpeg", name: "Henrique Morais Borghezan", instagram: "henrique_borghezan"
  }, {
    picture: "jailton-souza.png", name: "Jailton de Souza", instagram: "souzja"
  }, {
    picture: "luiz-pedro.png", name: "Luiz Pedro Goettems", instagram: "luiz_goettems"
  }, {
    picture: "alex-leseiko.png", name: "Alex Leseiko", instagram: "alexleseiko"
  }, {
    picture: "willian-silveira.png", name: "Willian Silveira", instagram: "willian_silveira3"
  }, {
    picture: "nicolas-fernandes.jpeg", name: "Nicolas G. Fernandes", instagram: "nicolasgfe"
  }, {
    picture: "matheus-wessling.png", name: "Matheus Dorneles Wessling", instagram: "matheus.wessling"
  }, {
    picture: "marcelo-lopes.png", name: "Marcelo Lopes", instagram: "marcelolopes69"
  }, {
    picture: "joao-pedro.jpeg", name: "João Pedro Baggio", instagram: "joao_cristofoli_180"
  }, {
    picture: "alan-nicoletti.jpeg", name: "Alam Nicoletti", instagram: "alan.nicolentti.14"
  }, {
    picture: "joao-lucas.jpeg", name: "João Lucas Gzik", instagram: "joaolucasgzik"
  }, {
    picture: "alan-matheus.jpeg", name: "Alan Mateus da Silva", instagram: "AlanMateus59"
  }, {
    picture: "rafael-chiele.jpeg", name: "Rafael Chiele", instagram: "rafaelchielee"
  }, {
    picture: "patrik-casali.jpeg", name: "Patrik Casali", instagram: "patrikcasali"
  }, {
    picture: "michel-giovani.jpg", name: "Michel Giovani", instagram: "michel_giovani_"
  },
];


const Participant = () => {
  return (
    <DefaultLayout>
      <section id="participants" className="participant container">
        <div className="participant-header">
          <h1>Participantes</h1>
          <h2>#SomosPinakays</h2>
        </div>
        <div className="participant-profile">
          <ParticipantProfileLine
            participants={participants}
          />
          <div className="container-participants">

          </div>
        </div>

      </section>
    </DefaultLayout>
  )
}

export default Participant;